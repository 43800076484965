@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@800&display=swap');

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper h1.glitch {
  @apply bg-white text-gray-900;
  font-weight: 800;
  position: relative;
  font-size: 175px;
  padding: 0px;
  font-family: 'Source Code Pro', monospace;
}
.wrapper h1.glitch::before, .wrapper h1.glitch::after {
  padding: 0px;
  content: "404";
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Source Code Pro', monospace;
  overflow: hidden;
  @apply bg-white text-gray-900;
  top: 0;
}
.wrapper h1.glitch::before {
  left: 2px;
  text-shadow: -2px 0 red;
  animation-name: glitch-animation-1;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: reverse-alternate;
}
.wrapper h1.glitch::after {
  left: -2px;
  text-shadow: 2px 0 blue;
  animation-name: glitch-animation-2;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: reverse-alternate;
}

@keyframes glitch-animation-1 {
  0% {
    clip: rect(47px, 350px, 106px, 0px);
  }
  5% {
    clip: rect(74px, 350px, 77px, 0px);
  }
  10% {
    clip: rect(74px, 350px, 148px, 0px);
  }
  15% {
    clip: rect(114px, 350px, 115px, 0px);
  }
  20% {
    clip: rect(28px, 350px, 23px, 0px);
  }
  25% {
    clip: rect(62px, 350px, 34px, 0px);
  }
  30% {
    clip: rect(13px, 350px, 74px, 0px);
  }
  35% {
    clip: rect(10px, 350px, 119px, 0px);
  }
  40% {
    clip: rect(112px, 350px, 16px, 0px);
  }
  45% {
    clip: rect(29px, 350px, 19px, 0px);
  }
  50% {
    clip: rect(67px, 350px, 125px, 0px);
  }
  55% {
    clip: rect(91px, 350px, 69px, 0px);
  }
  60% {
    clip: rect(43px, 350px, 12px, 0px);
  }
  65% {
    clip: rect(110px, 350px, 86px, 0px);
  }
  70% {
    clip: rect(111px, 350px, 113px, 0px);
  }
  75% {
    clip: rect(18px, 350px, 117px, 0px);
  }
  80% {
    clip: rect(46px, 350px, 124px, 0px);
  }
  85% {
    clip: rect(122px, 350px, 69px, 0px);
  }
  90% {
    clip: rect(45px, 350px, 116px, 0px);
  }
  95% {
    clip: rect(108px, 350px, 29px, 0px);
  }
  100% {
    clip: rect(8px, 350px, 60px, 0px);
  }
}
@keyframes glitch-animation-2 {
  0% {
    clip: rect(9px, 350px, 147px, 0px);
  }
  5% {
    clip: rect(64px, 350px, 48px, 0px);
  }
  10% {
    clip: rect(7px, 350px, 49px, 0px);
  }
  15% {
    clip: rect(14px, 350px, 77px, 0px);
  }
  20% {
    clip: rect(149px, 350px, 129px, 0px);
  }
  25% {
    clip: rect(136px, 350px, 75px, 0px);
  }
  30% {
    clip: rect(66px, 350px, 26px, 0px);
  }
  35% {
    clip: rect(58px, 350px, 37px, 0px);
  }
  40% {
    clip: rect(11px, 350px, 150px, 0px);
  }
  45% {
    clip: rect(71px, 350px, 53px, 0px);
  }
  50% {
    clip: rect(32px, 350px, 37px, 0px);
  }
  55% {
    clip: rect(73px, 350px, 19px, 0px);
  }
  60% {
    clip: rect(41px, 350px, 17px, 0px);
  }
  65% {
    clip: rect(61px, 350px, 17px, 0px);
  }
  70% {
    clip: rect(104px, 350px, 59px, 0px);
  }
  75% {
    clip: rect(146px, 350px, 10px, 0px);
  }
  80% {
    clip: rect(11px, 350px, 49px, 0px);
  }
  85% {
    clip: rect(87px, 350px, 26px, 0px);
  }
  90% {
    clip: rect(23px, 350px, 39px, 0px);
  }
  95% {
    clip: rect(31px, 350px, 129px, 0px);
  }
  100% {
    clip: rect(87px, 350px, 66px, 0px);
  }
}